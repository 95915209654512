var prev_tag;
var prev_production;
var init = true;
var choice;
$(document).ready(function(){

	$('b[role="presentation"]').hide();

	$('.filter select').on('change', function(){
		filter($(this));
	});

	if ($('input')[0]) {
		try {
			$('input').mlKeyboard({
				layout: 'en_US'
			});
		} catch (e) {

		}

	}

	$('#auto-check').on('keyup', function(){
		var value = $(this).val();

		$('#voorstel').empty();

		if (value) {
			$.ajax({
		        url: '/exists/' + value,
		        type: 'GET',
		        contentType: "application/json; charset=utf-8",
		        dataType:"json",
				async: false,
		        success: function (data) {
					var tags = '';

					$.each(data, function(key, value){
						tags += '<li>' + value.name + '</li> ';
					});

					if (data.id != "") {
						$('.auto-complete').show();
						$('#voorstel').html(tags);
					}
		        }
		    });
		} else {
			$('.auto-complete').hide();
		}

	});


	if ($('.multi')[0]) {
		$('.multi').multiselect();
	}

	$(document).on('click', '.favo', function(){
		addFavorite($(this));
	});

	$('.add-color').on('click', function(){
		$('.add-color-field').show();
	});
	$('#mobile-menu-button').on('click', function(){
		$('#mobile-menu').slideToggle();
	});
	$('.add-origin').on('click', function(){
		$('.add-origin-field').show();
	});
	

	$('.refresh-filters').on('click', function(){

		$("select option:selected").each(function () {
		    $(this).removeAttr('selected'); //or whatever else
		});



		$('.filter select[name="Ref"] option:first').attr('selected', 'selected').prop('selected', true).attr('manual', 'true').change();
		$('.filter select[name="nummer"] option:first').attr('selected', 'selected').prop('selected', true).attr('manual', 'true').change();
		$('option:first').attr('selected', 'selected').prop('selected', true).attr('manual', 'true').change();
		$('.filter select[name="kleur"]').val("");
		$('.filter select[name="kleur"]').trigger("change");
		$('.filter select[name="nuance"] option:first').attr('selected', 'selected').prop('selected', true).attr('manual', 'true').change();

		$('.filter select[name="formaat"] option:first').attr('selected', 'selected').prop('selected', true).attr('manual', 'true').change();
		$('.filter select[name="type"] option:first').attr('selected', 'selected').prop('selected', true).attr('manual', 'true').change();
	});

	function ulheight(){
		var windowheight = $(window).height();
		var navheight =  $('.home header').outerHeight();
		var navheightsub =  $('.social header').outerHeight();
		var ulheight = windowheight - navheight;
		var ulheightsub = windowheight - navheightsub;

		$('.diensten ul').height(ulheight);
		$('.diensten2 ul').height(windowheight);
		$('#socials ul').height(ulheightsub);
		$('#socials').css( "padding-top", navheightsub );
	}
	ulheight();

	function iframeheight(){
		var windowheight = $(window).height();

		$('iframe').height(windowheight);
	}
	iframeheight();
	var gridcontainer;
	if ($('.grid')[0]) {
		gridcontainer = $('.grid');
		gridcontainer.packery({
		  // options
		  itemSelector: '.grid-item',
		});
	}
	var togMon = 0;
	var togCon = 0;
	$('.monster').on('click', function(){
		$('.add-adress').toggle();
		$('.favobox').height(function (index, height) {
			/*if(togMon){
				togMon = 0;
				return (height - 70);
			} else {
				togMon = 1;
				return (height + 70);				
			}*/
		});
		gridcontainer.packery('layout');

	});
	
	$('.contactformshow').on('click', function(){
		$('.contact-form').toggle();
		$('.favobox').height(function (index, height) {
			/*if(togCon){
				togCon = 0;
				return (height - 200);
			} else {
				togCon = 1;
				return (height + 200);				
			}*/
		});
		gridcontainer.packery('layout');
	});

	$(window).resize(function(){
		ulheight();
		// heightLogo();
	});

	if ($("[data-fancybox='group']")[0]) {
		$("[data-fancybox='group']").fancybox({
			arrows : false,
			buttons : [
				'close'
			],
		});
	}


	// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('.fixedContent').outerHeight();

	$(window).scroll(function(event){
	    didScroll = true;
	});

	setInterval(function() {
	    if (didScroll) {
	        hasScrolled();
	        didScroll = false;
	    }
	}, 250);

	function hasScrolled() {
	    var st = $(this).scrollTop();

	    // Make sure they scroll more than delta
	    if(Math.abs(lastScrollTop - st) <= delta)
	        return;

	    // If they scrolled down and are past the navbar, add class .nav-up.
	    // This is necessary so you never see what is "behind" the navbar.
	    if (st > lastScrollTop && st > navbarHeight){
	        // Scroll Down
	        $('.fixedContent').removeClass('nav-down').addClass('nav-up');
	    } else {
	        // Scroll Up
	        if(st + $(window).height() < $(document).height()) {
	            $('.fixedContent').removeClass('nav-up').addClass('nav-down');
	        }
	    }

	    lastScrollTop = st;
	}
	$(window).scroll(function() {
		if($(window).scrollTop() + $(window).height() > ($(document).height()-600)) {
			addNextPage();
		}
	});

	// $(".dropzone").dropzone({ url: "/upload/images" });
});

if ($('.dropzone')[0]) {
	$(".dropzone").dropzone({
		maxFiles: 200,
		url: "/upload/images",
		headers: {
			'x-csrf-token': $('meta[name="csrf-token"]').attr('content'),
		},
		success: function (file, response) {
			// updateView(response);
		}
	});
}

if ($('.combobox')[0]) {
	$('.combobox').select2({
		closeOnSelect: true,
		
	});
}
if ($('.comboboxcol')[0]) {
	$('.comboboxcol').select2({
		containerCssClass: 'customselect',                 
		dropdownCss: {'border': '0px solid #fff !important;'},
		placeholder: "- Kleur -",
		closeOnSelect: false,
		maximumSelectionSize: 3,

	});
}

// INIT
if ($('.grid.container')[0] && !$('.fav')[0]) {
	initGrid();
}



function initGrid()
{
	
	var isRea = $('.filter select[name="Ref"]').val();
	var choice = $('.filter input[name="choice"]').val();
	var type = $('.filter select[name="type"]').val();
	var nummer = $('.filter select[name="nummer"]').val();
	var kleur = $('.filter select[name="kleur"]').val();
	var formaat = $('.filter select[name="formaat"]').val();
	var nuance = $('.filter select[name="nuance"]').val();


	$.ajax({
        url: '/filter/realisaties/' + type + '/' + nummer + '/' +  kleur + '/' + formaat  + '/' + isRea + '/' + choice + '/' + nuance,
        type: 'GET',
        contentType: "application/json; charset=utf-8",
        dataType:"json",
		async: false,
        success: function (data) {
			$('.grid.container').empty();
			var next = data.next_page_url;
			window.globalVar = next;

			var html = "";
			$.each(data.data, function(key, value){
				var favo = false;
				console.log(value.heart);
				for (var index = 0; index < value.heart.length; index++) {
					var heart = value.heart[index];
					if (heart && heart.user_id == $('[name="ATK"]').val()) {
						var favo = true;
					}
				}
				

                // Get thumb
				var thumb  = (value.path).split('/');
				thumb = thumb[0] + '/thumbs/' + thumb[1];
				base = thumb;
				thumb = (thumb.split('.'));

				if (value.created_at > '2018-01-31 00:00:00') {
					thumb = base;
				} else {
					thumb = thumb[0] + '.jpg';
				}

				// $('.grid.container').append('<div class="grid-item" style="background-image: url(/' + value.path + ');"><a data-fancybox="group" href="/'+ value.path + '"></a><div class="layer"></div><div class="tag">' + value.tag.name + '</div><div class="favo"><i class="fa fa-star-o" aria-hidden="true"></i></div></div>');
				if(value.isTop){
					html += '<div class="grid-item" src=/'+ value.path + ' style="background-image: url(/' + thumb + ');"><a data-fancybox="group" href="/'+ value.path + '"></a><div class="layer"></div><div class="tag">' + value.tag.name + '</div><a style="width:20px;height:20px;position: absolute; top: 9px; left: auto; right: 10px;"target="_blank" href="http://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent($(location).attr("href")) + '&media=https://steenboek.altena-steenhandel.nl/' +encodeURIComponent(value.path) + '&description=' + 'Grave stenen van Altena Steenhandel!"><img src="/images/badgeRGB.svg"></a><div class="favo ' + ((favo)? "active" : "" )+ '" realisatie-id="' + value.id + '"> <i class="fa fa-star-o" aria-hidden="true"></i></div></div>';

				} else {
					html += '<div class="grid-item" src=/'+ value.path + ' style="background-image: url(/' + thumb + ');"><a data-fancybox="group" href="/'+ value.path + '"></a><div class="layer"></div><div class="tag">' + value.tag.name + '</div><a style="width:20px;height:20px;position: absolute; top: 9px; left: auto; right: 10px;"target="_blank" href="http://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent($(location).attr("href")) + '&media=https://steenboek.altena-steenhandel.nl/' +encodeURIComponent(value.path) + '&description=' + 'Grave stenen van Altena Steenhandel!"><img src="/images/badgeRGB.svg"></a><div class="favo ' + ((favo)? "active" : "" )+ '" realisatie-id="' + value.id + '"><i class="fa fa-star-o" aria-hidden="true"></i></div></div>';

				}

			});

			var $container = $('.grid').packery();
			var $html = $(html);

			$container.append($html);
			$container.packery('appended', $html);
        }
    });
}

function addFavorite(that)
{
	$.ajax({
        url: '/favorite/' + $(that).attr('realisatie-id'),
        type: 'GET',
        contentType: "application/json; charset=utf-8",
        dataType:"json",
		async: false,
        success: function (data) {
			if ($(that).hasClass('active')) {
				$(that).removeClass('active');
			} else {
				$(that).addClass('active');
			}
        }
    });
}

function filter(that)
{
	var isRea = $('.filter select[name="Ref"]').val();
	var choice = $('.filter input[name="choice"]').val();
	var type = $('.filter select[name="type"]').val();
	var nummer = $('.filter select[name="nummer"]').val();
	var kleur = $('.filter select[name="kleur"]').val();
	var formaat = $('.filter select[name="formaat"]').val();
	var nuance = $('.filter select[name="nuance"]').val();


	// if ($('[manual]')[0]) {
	// 	prev_tag = "";
	// 	console.log("REFRESH");
	// } else {
	// 	init = false;
	// }

	$.ajax({
        url: '/filter/realisaties/' + type + '/' + nummer + '/' +  kleur + '/' + formaat + '/' + isRea + '/' + choice + '/' + nuance,
        type: 'GET',
        contentType: "application/json; charset=utf-8",
        dataType:"json",
		async: false,
        success: function (data) {
			$('.grid.container').empty();
			
			var next = data.next_page_url;
			window.globalVar = next;

			var html = "";
			$.each(data.data, function(key, value){
				var favo = false;

				if (value.heart && value.heart.user_id == $('[name="ATK"]').val()) {
					var favo = true;
				}

				// Get thumb
				var thumb  = (value.path).split('/');
				thumb = thumb[0] + '/thumbs/' + thumb[1];
				base = thumb;
				thumb = (thumb.split('.'));

				if (value.created_at > '2018-01-31 00:00:00') {
					thumb = base;
				} else {
					thumb = thumb[0] + '.jpg';
				}

				// $('.grid.container').append('<div class="grid-item" style="background-image: url(/' + value.path + ');"><a data-fancybox="group" href="/'+ value.path + '"></a><div class="layer"></div><div class="tag">' + value.tag.name + '</div><div class="favo"><i class="fa fa-star-o" aria-hidden="true"></i></div></div>');
				if(value.isTop){
					html += '<div src=/'+ value.path + '  class="grid-item" style="background-image: url(/' + thumb + ');"><a data-fancybox="group" href="/'+ value.path + '"></a><div class="layer"></div><div class="tag">' + value.tag.name + '</div><a style="width:20px;height:20px;position: absolute; top: 9px; left: auto; right: 10px;"target="_blank" href="http://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent($(location).attr("href")) + '&media=https://steenboek.altena-steenhandel.nl/' +encodeURIComponent(value.path) + '&description=' + 'Grave stenen van Altena Steenhandel!"><img src="/images/badgeRGB.svg"></a><div class="favo ' + ((favo)? "active" : "" )+ '" realisatie-id="' + value.id + '"> <i class="fa fa-star-o" aria-hidden="true"></i></div></div>';

				} else {
					html += '<div src=/'+ value.path + '  class="grid-item" style="background-image: url(/' + thumb + ');"><a data-fancybox="group" href="/'+ value.path + '"></a><div class="layer"></div><div class="tag">' + value.tag.name + '</div><a style="width:20px;height:20px;position: absolute; top: 9px; left: auto; right: 10px;"target="_blank" href="http://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent($(location).attr("href")) + '&media=https://steenboek.altena-steenhandel.nl/' +encodeURIComponent(value.path) + '&description=' + 'Grave stenen van Altena Steenhandel!"><img src="/images/badgeRGB.svg"></a><div class="favo ' + ((favo)? "active" : "" )+ '" realisatie-id="' + value.id + '"><i class="fa fa-star-o" aria-hidden="true"></i></div></div>';

				}
			});

			var $container = $('.grid').packery();
			var $html = $(html);

			$container.append($html);
			$container.packery('appended', $html);
			if(!data.data.length){
				$('.grid.container').css('height', '150px');
				$('.grid.container').html('<div style="text-align: center;"><h4>Geen foto\'s gevonden, neem contact op zodat we u met andere mogelijkheden kunnen verder helpen</h4></div>');
			}
        }
    });
}

function addNextPage()
{
	var isRea = $('.filter select[name="Ref"]').val();
	var choice = $('.filter select[name="choice"]').val();
	var type = $('.filter select[name="type"]').val();
	var naam = $('.filter select[name="naam"]').val();
	var kleur = $('.filter select[name="kleur"]').val();
	var formaat = $('.filter select[name="formaat"]').val();
	$.ajax({
        url: window.globalVar,
        type: 'GET',
        contentType: "application/json; charset=utf-8",
        dataType:"json",
		async: false,
        success: function (data) {
			var next = data.next_page_url;
			window.globalVar = next;
			// $('.grid.container').empty();
			var html = "";
			$.each(data.data, function(key, value){
				var favo = false;
				if (value.heart && value.heart.user_id == $('[name="ATK"]').val()) {
					var favo = true;
				}

				// Get thumb
				var thumb  = (value.path).split('/');
				thumb = thumb[0] + '/thumbs/' + thumb[1];
				base = thumb;
				thumb = (thumb.split('.'));

				if (value.created_at > '2018-01-31 00:00:00') {
					thumb = base;
				} else {
					thumb = thumb[0] + '.jpg';
				}

				// $('.grid.container').append('<div class="grid-item" style="background-image: url(/' + value.path + ');"><a data-fancybox="group" href="/'+ value.path + '"></a><div class="layer"></div><div class="tag">' + value.tag.name + '</div><div class="favo"><i class="fa fa-star-o" aria-hidden="true"></i></div></div>');
				//html += '<div class="grid-item" style="background-image: url(/' + thumb + ');"><a data-fancybox="group" href="/'+ value.path + '"></a><div class="layer"></div><div class="tag">' + value.tag.name + '</div><a style="width:20px;height:20px;position: absolute; top: 9px; left: auto; right: 10px;"target="_blank" href="http://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent($(location).attr("href")) + '&media=https://steenboek.altena-steenhandel.nl/' +encodeURIComponent(value.path) + '&description=' + 'Grave stenen van Altena Steenhandel!"><img src="/images/badgeRGB.svg"></a><div class="favo ' + ((favo)? "active" : "" )+ '" realisatie-id="' + value.id + '"><i class="fa fa-star-o" aria-hidden="true"></i></div></div>';
				if(value.isTop){
					html += '<div src=/'+ value.path + ' class="grid-item" style="background-image: url(/' + thumb + ');"><a data-fancybox="group" href="/'+ value.path + '"></a><div class="layer"></div><div class="tag">' + value.tag.name + '</div><a style="width:20px;height:20px;position: absolute; top: 9px; left: auto; right: 10px;"target="_blank" href="http://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent($(location).attr("href")) + '&media=https://steenboek.altena-steenhandel.nl/' +encodeURIComponent(value.path) + '&description=' + 'Grave stenen van Altena Steenhandel!"><img src="/images/badgeRGB.svg"></a><div class="favo ' + ((favo)? "active" : "" )+ '" realisatie-id="' + value.id + '"> <i class="fa fa-star-o" aria-hidden="true"></i></div></div>';

				} else {
					html += '<div src=/'+ value.path + ' class="grid-item" style="background-image: url(/' + thumb + ');"><a data-fancybox="group" href="/'+ value.path + '"></a><div class="layer"></div><div class="tag">' + value.tag.name + '</div><a style="width:20px;height:20px;position: absolute; top: 9px; left: auto; right: 10px;"target="_blank" href="http://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent($(location).attr("href")) + '&media=https://steenboek.altena-steenhandel.nl/' +encodeURIComponent(value.path) + '&description=' + 'Grave stenen van Altena Steenhandel!"><img src="/images/badgeRGB.svg"></a><div class="favo ' + ((favo)? "active" : "" )+ '" realisatie-id="' + value.id + '"><i class="fa fa-star-o" aria-hidden="true"></i></div></div>';

				}
			});

			var $container = $('.grid').packery();
			var $html = $(html);

			$container.append($html);
			$container.packery('appended', $html);
        }
    });
}


function lookForScan()
{
	$.ajax({
		url: '/get/scanned/tag',
		type: 'GET',
		contentType: "application/json; charset=utf-8",
		dataType:"json",
		async: false,
		success: function (data) {

			if (data == "false") {
				$('.scanned_tag').html('Deze tag is reeds gelinkt. Scan een nieuwe tag').css({'color': 'red', 'font-weight': 'bold'});
				$('[name="scan_id"]').val("");
			} else if (data == "no_tag") {
				$('.scanned_tag').html('Gelieve een tag te scannen').css({'color': 'red', 'font-weight': 'bold'});
				$('[name="scan_id"]').val("");
			}else if (data.tag_id) {
				$('.scanned_tag').html('Deze tag is reeds gelinkt. Scan een nieuwe tag.').css({'color': 'red', 'font-weight': 'bold'});
				$('[name="scan_id"]').val("");
			} else {
				$('.scanned_tag').html(data.uid).css({'color': 'green', 'font-weight': 'bold'});
				$('[name="scan_id"]').val(data.uid);
			}

			setTimeout(function(){lookForScan();}, 2000);
		},
		error: function(XMLHttpRequest, textStatus, errorThrown) {
			setTimeout(function(){lookForScan();}, 2000);
	    }
	});
}


function waitForScan()
{
	// if (window.location.href != 'http://localhost:8000/lookbook') {
	// 	window.location.replace("http://localhost:8000/lookbook");
	// }

	$.ajax({
		url: '/get/scanned/tag/dashboard',
		type: 'GET',
		contentType: "application/json; charset=utf-8",
		dataType:"json",
		async: false,
		success: function (data) {
			if (data == 'reset' || data == "killswitch") {
			// if (data == "killswitch") {
				$('.refresh-filters').click();
			}
			$('input[type="hidden"][name="state"]').val('going');

			if (data.tag_id && prev_tag != data.tag_id) {
				$("select option").prop("selected", false);
				// $('select[name="naam"]').find('option[value="' + data.tag_id + '"]').attr('selected', 'selected').change();

				$('[name=naam] option').filter(function() {
					return ($(this).val() == data.tag_id);
				}).prop('selected', true).change();

				$.fancybox.close();
			}

			prev_tag = data.tag_id;

			setTimeout(function(){waitForScan();}, 2000);
		},
		error: function(XMLHttpRequest, textStatus, errorThrown) {
			if ($('input[type="hidden"][name="state"]').val() == 'going') {
				if ($('select[name="naam"]').find(':selected').val() != "null") {
					$('.refresh-filters').click();
				}
				$('input[type="hidden"][name="state"]').val('halted');
			}
			setTimeout(function(){waitForScan();}, 2000);
	    }
	});
}